<template>
    <div id="login-section" class="align-items-center d-flex">
        <div class="container">
            <div class="row justify-content-center">
                <div
                    class="bg-opacity-25 bg-primary border border-primary col-10 col-sm-5 my-5 p-sm-5 px-2 py-4 rounded">
                   <login-component/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LoginComponent from "@/components/home/Login";

export default {
    name   : "Login",
    components: {LoginComponent}
};
</script>
